import Link from "next/link";
import { partners_data } from "../../data/partners_data";

const hero = () => {
  return (
    <>
      <section className="relative pb-10 pt-20 md:pt-32 h-1527">
        <picture className="pointer-events-none absolute inset-x-0 top-0 -z-10 block dark:hidden h-full">
          <img
            src="/images/gradient.jpg"
            alt="gradient"
            className="h-full w-full"
          />
        </picture>
        <picture className="pointer-events-none absolute inset-x-0 top-0 -z-10 hidden dark:block">
          <img
            src="/images/gradient_dark.jpg"
            alt="gradient dark"
            className="h-full w-full"
          />
        </picture>

        <div className="container h-full mx-auto">
          <div className="grid h-full items-center gap-4 md:grid-cols-12">
            <div className="col-span-6 flex h-full flex-col items-center justify-center py-10 md:items-start md:py-20 xl:col-span-5">
              <h1 className="text-jacarta-700 font-bold font-display mb-6 text-center text-5xl dark:text-white md:text-left lg:text-6xl xl:text-7xl">
                Reflect Doge
              </h1>
              <p className="dark:text-jacarta-200 mb-8 text-center text-lg md:text-left">
                Reflect, repent and make BSC and POM great again!
              </p>
              <div className="flex space-x-4">
                <Link href="https://t.me/reflectdoge">
                  <a className="bg-accent shadow-accent-volume hover:bg-accent-dark rounded-full py-3 px-8 text-center font-semibold text-white transition-all">
                    Join our Community
                  </a>
                </Link>
                <Link href="/files/Reflectdoge-whitepaper.pdf">
                  <a className="text-accent shadow-white-volume hover:bg-accent-dark hover:shadow-accent-volume rounded-full bg-white py-3 px-8 text-center font-semibold transition-all hover:text-white">
                    Whitepaper
                  </a>
                </Link>
              </div>
            </div>

            {/* <!-- Hero image --> */}
            <div className="col-span-6 xl:col-span-7">
              <div className="relative text-center md:pl-7 md:text-right">
                <img
                  src="/images/home/reflect-doge-home-2.jpg"
                  alt=""
                  className="hero-img mt-8 inline-block w-72 rotate-[8deg] sm:w-full lg:w-[24rem] xl:w-[35rem]"
                />
                <img
                  src="/images/hero/3D_elements.png"
                  alt=""
                  className="animate-fly absolute top-0 md:-right-[10%]"
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* <!-- Benefits --> */}
      <section className="relative pt-5 pb-5">
        <div className="container">
          <div className="mx-auto max-w-xl text-center">
            <h2 className="font-display text-jacarta-700 mb-6 text-center text-4xl font-medium dark:text-white">
              BSC Tokenomics and tax breakdown
            </h2>
          </div>

          <div className="grid gap-7 md:grid-cols-3">
            <div className="dark:bg-jacarta-800 rounded-2.5xl bg-white p-[3.75rem] text-center transition-shadow hover:shadow-xl">
              <div className="mb-6 inline-flex rounded-full bg-[#FFAB5A] p-2.5">
                <div className="bg-accent inline-flex h-[6.25rem] w-[6.25rem] items-center justify-center rounded-full">
                  <img
                    src="/images/home/bsc-1.jpg"
                    alt="gradient"
                    className="h-full w-full rounded-full"
                  />
                </div>
              </div>
              <h3 className="font-display text-jacarta-700 mx-auto mb-4 max-w-[9.625rem] text-lg dark:text-white">
                GENERAL
              </h3>
              <p className="dark:text-jacarta-300">
                Supply: 1,000,000,000,000
                <br />
                Burned: 70% LP Burned Forever
                <br />
                CA Renounced
              </p>
            </div>

            <div className="dark:bg-jacarta-800 rounded-2.5xl bg-white p-[3.75rem] text-center transition-shadow hover:shadow-xl">
              <div className="mb-6 inline-flex rounded-full bg-[#FFAB5A] p-2.5">
                <div className="bg-accent inline-flex h-[6.25rem] w-[6.25rem] items-center justify-center rounded-full">
                  <img
                    src="/images/home/bsc-2.jpg"
                    alt="gradient"
                    className="h-full w-full rounded-full"
                  />
                </div>
              </div>
              <h3 className="font-display text-jacarta-700 mx-auto mb-4 max-w-[9.625rem] text-lg dark:text-white">
                7% BUY TAX
              </h3>
              <p className="dark:text-jacarta-300">
                1% Reflection
                <br />
                6% Shared between
                <br />
                Liquidity Pool
                <br />
                Development
                <br />
                Marketing
                <br />
                Burn
              </p>
            </div>

            <div className="dark:bg-jacarta-800 rounded-2.5xl bg-white p-[3.75rem] text-center transition-shadow hover:shadow-xl">
              <div className="mb-6 inline-flex rounded-full bg-[#FFAB5A] p-2.5">
                <div className="bg-accent inline-flex h-[6.25rem] w-[6.25rem] items-center justify-center rounded-full">
                  <img
                    src="/images/home/bsc-3.jpg"
                    alt="gradient"
                    className="h-full w-full rounded-full"
                  />
                </div>
              </div>
              <h3 className="font-display text-jacarta-700 mx-auto mb-4 max-w-[9.625rem] text-lg dark:text-white">
                9% SELL TAX
              </h3>
              <p className="dark:text-jacarta-300">
                1% Reflection
                <br />
                8% Shared between
                <br />
                Liquidity Pool
                <br />
                Development
                <br />
                Marketing
                <br />
                Burn
              </p>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- end benefits --> */}

      {/* <!-- Benefits --> */}
      <section className="relative pt-5 pb-5">
        <div className="container">
          <div className="mx-auto max-w-xl text-center">
            <h2 className="font-display text-jacarta-700 mb-6 text-center text-4xl font-medium dark:text-white">
              POM Tokenomics and tax breakdown
            </h2>
          </div>

          <div className="grid gap-7 md:grid-cols-3">
            <div className="dark:bg-jacarta-800 rounded-2.5xl bg-white p-[3.75rem] text-center transition-shadow hover:shadow-xl">
              <div className="mb-6 inline-flex rounded-full bg-[#FFAB5A] p-2.5">
                <div className="bg-accent inline-flex h-[6.25rem] w-[6.25rem] items-center justify-center rounded-full">
                  <img
                    src="/images/home/pom-1.jpg"
                    alt="gradient"
                    className="h-full w-full rounded-full"
                  />
                </div>
              </div>
              <h3 className="font-display text-jacarta-700 mx-auto mb-4 max-w-[9.625rem] text-lg dark:text-white">
                GENERAL
              </h3>
              <p className="dark:text-jacarta-300">
                Supply: 1,000,000,000,000
                <br />
                Burned: 10% LP Burned Forever
                <br />
                CA Renounced
              </p>
            </div>

            <div className="dark:bg-jacarta-800 rounded-2.5xl bg-white p-[3.75rem] text-center transition-shadow hover:shadow-xl">
              <div className="mb-6 inline-flex rounded-full bg-[#FFAB5A] p-2.5">
                <div className="bg-accent inline-flex h-[6.25rem] w-[6.25rem] items-center justify-center rounded-full">
                  <img
                    src="/images/home/pom-2.jpg"
                    alt="gradient"
                    className="h-full w-full rounded-full"
                  />
                </div>
              </div>
              <h3 className="font-display text-jacarta-700 mx-auto mb-4 max-w-[9.625rem] text-lg dark:text-white">
                5% BUY TAX
              </h3>
              <p className="dark:text-jacarta-300">
                1% Reflection
                <br />
                4% Shared between
                <br />
                Liquidity Pool
                <br />
                Development
                <br />
                Marketing
                <br />
                Burn
              </p>
            </div>

            <div className="dark:bg-jacarta-800 rounded-2.5xl bg-white p-[3.75rem] text-center transition-shadow hover:shadow-xl">
              <div className="mb-6 inline-flex rounded-full bg-[#FFAB5A] p-2.5">
                <div className="bg-accent inline-flex h-[6.25rem] w-[6.25rem] items-center justify-center rounded-full">
                  <img
                    src="/images/home/pom-3.jpg"
                    alt="gradient"
                    className="h-full w-full rounded-full"
                  />
                </div>
              </div>
              <h3 className="font-display text-jacarta-700 mx-auto mb-4 max-w-[9.625rem] text-lg dark:text-white">
                5% SELL TAX
              </h3>
              <p className="dark:text-jacarta-300">
                1% Reflection
                <br />
                4% Shared between
                <br />
                Liquidity Pool
                <br />
                Development
                <br />
                Marketing
                <br />
                Burn
              </p>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- end benefits --> */}
    </>
  );
};

export default hero;
